'use strict';

angular.module('informaApp')
    .service('Legend', ['d3', 'ChartElementsHelper', function (d3, ChartElementsHelper) {
        return function (legendContainer, chartData, colors, options = {}) {
            var _this = this;

            this.onMouseEnter = null;
            this.onMouseLeave = null;
            this.onClick = null;

            var rectSize = 12;
            var itemTopSize = 25;
            var fontSize = 13;
            let labelOffset = options.legendItemOffset || 0;
            const rectPadding = 10;

            this.getWidth = getWidth;

            var legend = null;
            redraw();
            
            function redraw() {
                const data = chartData.slice(labelOffset, labelOffset + (options.maxItems || chartData.length));
                const yOffset = chartData.length === data.length ? 0 : 22;

                legendContainer
                    .selectAll('g, text')
                    .remove();

                legend = legendContainer
                    .selectAll('g')
                    .data(data)
                    .enter()
                    .append('g')
                    .attr('transform', function (d, i) {
                        return ChartElementsHelper.translate(0, i * itemTopSize + yOffset);
                    })
                    .attr('height', itemTopSize + 'px');

                legend.append('rect')
                    .attr('width', rectSize)
                    .attr('height', rectSize)
                    .attr('transform', function (d, i) {
                        return ChartElementsHelper.translate(0, itemTopSize / 2 - rectSize / 2);
                    })
                    .style('fill', function (d, i) {
                        return colors[(i + labelOffset) % colors.length];
                    });

                const legendItemLabels = legend
                    .append('text')
                    .attr('class', 'legend-item-name')
                    .attr('x', rectSize + rectPadding)
                    .attr('y', 17)
                    .attr('font-size', fontSize + 'px')
                    .text(function (d) {
                        return d.name;
                    })
                    .style('text-decoration', function (d, i) {
                        return !data[i].disabled ? null : 'line-through';
                    });

                legend
                    .append('rect')
                    .attr('width', '100%')
                    .attr('height', (itemTopSize) + 'px')
                    .attr('opacity', '0')
                    .attr('transform', function (d, i) {
                        return ChartElementsHelper.translate(0, itemTopSize / 2 - rectSize / 2 - 5);
                    })
                    .on('click', function (d, i) {
                        var disabledItems = chartData.filter(function (e) {
                            return e.disabled;
                        });

                        if (!data[i].disabled && disabledItems.length >= chartData.length - 1) {
                            return;
                        }

                        data[i].disabled = !data[i].disabled;
                        updateTexts(this, data[i].disabled);

                        callEvent((d, i) => _this.onClick(d, i + labelOffset, labelOffset), this, arguments);
                    })
                    .on('mouseover', function () {
                        callEvent((d, i) => _this.onMouseEnter(d, i + labelOffset, labelOffset), this, arguments);
                    })
                    .on('mouseleave',  function () {
                        callEvent((d, i) => _this.onMouseLeave(d, i + labelOffset, labelOffset), this, arguments);
                    });

                if (options.horizontal) {
                    let offset = 0;

                    legend.attr('transform', function () {
                        const translate = ChartElementsHelper.translate(offset, 0);

                        offset += getLegendItemWidth(this) + rectPadding;

                        return translate;
                    });

                    return;
                }

                if (chartData.length === data.length) {
                    return;
                }

                const legendWidth = legendItemLabels[0][legendItemLabels.size() - 1].getComputedTextLength()

                legendContainer.append('text')
                    .attr('class', 'glyphicon trends-view-legend-arrow')
                    .text('\ue259')
                    .attr('font-size', '22px')
                    .attr('dy', '1em')
                    .attr('transform', function () {
                        return ChartElementsHelper.translate(legendWidth / 2, data.length * itemTopSize + yOffset);
                    })
                    .on('click', next);

                legendContainer.insert('text')
                    .attr('class', 'glyphicon trends-view-legend-arrow')
                    .text('\ue260')
                    .attr('font-size', '22px')
                    .attr('dy', '1em')
                    .attr('transform', function () {
                        return ChartElementsHelper.translate(legendWidth / 2, 0);
                    })
                    .on('click', back);
            }

            function next() {
                if (labelOffset + options.maxItems >= chartData.length) {
                    return;
                }

                labelOffset++;
                redraw();
            }

            function back() {
                if (labelOffset === 0) {
                    return;
                }

                labelOffset--;
                redraw();
            }
            
            function getWidth() {
                return options.horizontal
                    ? legend[0].reduce((result, legendItemContainer) => result + getLegendItemWidth(legendItemContainer) + rectPadding, 0)
                    : getLegendItemWidth(legend.nodes()[0]);
            }

            function getLegendItemWidth(container) {
                const width = container.getElementsByTagName('text')[0].getComputedTextLength();

                return width + rectSize + rectPadding;
            }

            function updateTexts(context, disabled) {
                var textDecoration = disabled ? 'line-through' : null;

                d3.select(context.parentNode)
                    .select('text.legend-item-name')
                    .style('text-decoration', textDecoration);
            }

            function callEvent(fn, context, args) {
                if (fn) {
                    fn.apply(context, args);
                }
            }
        };
    }]);
